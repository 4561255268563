<template>
  <div
    class="text__formatter--container"
    :style="showFormatBlock ? 'position: relative;' : ''"
  >
    <div
      ref="textFormatter"
      class="text-formatter"
      contenteditable="true"
      :placeholder="placeholder"
      @mouseup="showTooltip"
      @input="updateTextCount"
      @keydown="handleListContinuation"
      @click="handleLinkClick"
      @focus="handleFocus"
      @blur="handleBlur"
      @paste="handlePaste"
      v-html="initialContent"
    ></div>
    <img
      class="format-block"
      :src="formatBlock"
      alt="Formatter"
      v-if="showFormatBlock"
    />
    <div
      v-if="isTooltipVisible"
      class="tooltip-box"
      :style="tooltipStyle"
      ref="tooltipContent"
    >
      <ul class="tooltip-content">
        <li
          :class="{ active: isFormattingActive('bold') }"
          @click="formatText('bold')"
        >
          <img
            :src="isFormattingActive('bold') ? boldActiveIcon : boldIcon"
            alt="Bold"
          />
        </li>
        <li
          :class="{ active: isFormattingActive('italic') }"
          @click="formatText('italic')"
        >
          <img
            :src="isFormattingActive('italic') ? italicActiveIcon : italicIcon"
            alt="Italic"
          />
        </li>
        <li
          :class="{ active: isFormattingActive('insertUnorderedList') }"
          @click="formatText('insertUnorderedList')"
        >
          <img
            :src="
              isFormattingActive('insertUnorderedList')
                ? bulletListActiveIcon
                : bulletListIcon
            "
            alt="Bullet List"
          />
        </li>
        <li
          :class="{ active: isFormattingActive('insertOrderedList') }"
          @click="formatText('insertOrderedList')"
        >
          <img
            :src="
              isFormattingActive('insertOrderedList')
                ? numberListActiveIcon
                : numberListIcon
            "
            alt="Number List"
          />
        </li>
        <li @click="createLink">
          <img :src="linkIcon" alt="Link" />
        </li>
      </ul>
    </div>
    <div
      v-if="isLinkActionVisible"
      class="link-action"
      :style="linkActionStyle"
    >
      <img :src="linkBlackIcon" alt="Link" />
      <input
        v-model="linkUrl"
        :placeholder="$t('general.text_format.link_placeholder')"
        @input="updateLink"
      />
      <span class="link-action-btn save-link" @click="saveLink">
        <img :src="addLink" alt="OK" />
      </span>
      <span
        class="link-action-btn remove-link"
        @click="removeLink"
        v-if="isEditingLink"
      >
        <img :src="deleteLink" alt="Delete" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: String,
    initialContent: String
  },
  data() {
    return {
      boldIcon: require("@/assets/images/text-formatter/bold.svg"),
      boldActiveIcon: require("@/assets/images/text-formatter/bold-active.svg"),
      italicIcon: require("@/assets/images/text-formatter/italic.svg"),
      italicActiveIcon: require("@/assets/images/text-formatter/italic-active.svg"),
      bulletListIcon: require("@/assets/images/text-formatter/bullet-list.svg"),
      bulletListActiveIcon: require("@/assets/images/text-formatter/bullet-list-active.svg"),
      numberListIcon: require("@/assets/images/text-formatter/number-list.svg"),
      numberListActiveIcon: require("@/assets/images/text-formatter/number-list-active.svg"),
      linkIcon: require("@/assets/images/text-formatter/link.svg"),
      linkBlackIcon: require("@/assets/images/text-formatter/link-black.svg"),
      addLink: require("@/assets/images/vle/checkmark-lightblue.svg"),
      deleteLink: require("@/assets/images/vle/delete-red.svg"),
      formatBlock: require("@/assets/images/vle/format-block.svg"),
      isTooltipVisible: false,
      tooltipStyle: {
        top: "0px",
        left: "0px"
      },
      isLinkActionVisible: false,
      linkActionStyle: {
        top: "0px",
        left: "0px"
      },
      selectedLinkNode: null,
      linkUrl: "",
      isEditingLink: false,
      savedSelection: null,
      selectedText: "",
      firstTimeFocus: true,
      showFormatBlock: false,
      isCopiedContent: false
    };
  },
  methods: {
    updateTextCount(event) {
      this.$emit("input", event.target.textContent);
      this.showFormatBlock = false;
    },
    handleFocus() {
      this.$emit("focus");
      if (this.firstTimeFocus) {
        this.firstTimeFocus = false;
        this.showFormatBlock = true;
      }
    },
    handleBlur(event) {
      this.$emit("blur");
      if (this.isCopiedContent) {
        this.$emit("inputHTML", event.target.textContent);
      } else {
        this.$emit("inputHTML", event.target.innerHTML);
      }
      this.showFormatBlock = false;
    },
    outsideClickHandler(event) {
      const textFormatterElement = this.$refs.textFormatter;
      if (
        textFormatterElement &&
        !textFormatterElement.contains(event.target)
      ) {
        this.isTooltipVisible = false;
        document.removeEventListener("click", this.outsideClickHandler);
      }
    },
    showTooltip() {
      this.selectedText = this.getSelectedText();
      if (this.selectedText) {
        document.addEventListener("click", this.outsideClickHandler);
        this.isTooltipVisible = true;
        const range = window.getSelection().getRangeAt(0);
        this.savedSelection = range.cloneRange();

        const span = document.createElement("span");
        span.textContent = this.selectedText;
        range.insertNode(span);

        const rect = span.getBoundingClientRect();
        const pointerLeft = rect.left + rect.width / 2;

        const tooltipHeight = this.$refs.tooltipContent
          ? this.$refs.tooltipContent.clientHeight
          : 0;

        const editorRect = this.$refs.textFormatter.getBoundingClientRect();

        const top =
          rect.top + window.scrollY + tooltipHeight > editorRect.bottom
            ? rect.top + window.scrollY - tooltipHeight
            : rect.bottom + window.scrollY;

        span.parentNode.removeChild(span);

        this.tooltipStyle = {
          top: `${top}px`,
          left: `${pointerLeft}px`
        };
      } else {
        this.isTooltipVisible = false;
      }
    },
    getSelectedText() {
      const selection = window.getSelection();
      return selection.toString();
    },
    formatText(command) {
      document.execCommand(command, false, null);
      this.isTooltipVisible = false;
    },
    handleListContinuation(event) {
      if (event.key === "Enter") {
        const selection = window.getSelection();
        const parentElement = selection.focusNode.parentElement;

        if (
          parentElement &&
          (parentElement.tagName === "OL" || parentElement.tagName === "UL")
        ) {
          event.preventDefault();

          const listItem = document.createElement("LI");
          listItem.innerHTML = "<br>";
          const range = selection.getRangeAt(0);
          range.insertNode(listItem);

          range.setStart(listItem, 0);
          range.setEnd(listItem, 0);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
      this.isTooltipVisible = false;
    },
    createLink() {
      this.linkUrl = "";
      this.isEditingLink = false;
      this.isTooltipVisible = false;
      this.isLinkActionVisible = true;

      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const rectRange = range.getBoundingClientRect();
      const editorRect = this.$refs.textFormatter.getBoundingClientRect();
      const tooltipHeight = this.$refs.tooltipContent
        ? this.$refs.tooltipContent.clientHeight
        : 0;

      const top =
        rectRange.top + window.scrollY + tooltipHeight > editorRect.bottom
          ? rectRange.top + window.scrollY - tooltipHeight
          : rectRange.bottom + window.scrollY;

      this.linkActionStyle = {
        top: `${top}px`,
        left: `${rectRange.left}px`
      };
    },
    saveLink() {
      const url = this.linkUrl.trim();
      if (url && this.savedSelection) {
        const range = this.savedSelection;
        const linkText = document.createTextNode(this.selectedText);
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.appendChild(linkText);

        range.deleteContents();
        range.insertNode(tempLink);

        this.savedSelection = null;
      }
      this.isLinkActionVisible = false;
    },
    removeLink() {
      if (this.selectedLinkNode) {
        const textNode = document.createTextNode(
          this.selectedLinkNode.textContent
        );
        this.selectedLinkNode.replaceWith(textNode);
        this.isLinkActionVisible = false;
      }
    },
    handleLinkClick(event) {
      const target = event.target;
      if (target.tagName === "A") {
        this.isLinkActionVisible = true;
        this.linkUrl = target.getAttribute("href");
        this.selectedLinkNode = target;
        const rect = target.getBoundingClientRect();
        this.linkActionStyle = {
          top: `${rect.bottom}px`,
          left: `${rect.left}px`
        };
        this.isEditingLink = true;
      } else {
        this.isLinkActionVisible = false;
      }
    },
    updateLink() {
      if (this.selectedLinkNode) {
        this.selectedLinkNode.setAttribute("href", this.linkUrl);
      }
    },
    handlePaste(event) {
      this.isCopiedContent = true;
      event.preventDefault();
      const text = (event.originalEvent || event).clipboardData.getData(
        "text/plain"
      );
      const plainText = text.replace(/<div[^>]*>|<\/div>/g, "");
      document.execCommand("insertText", false, plainText);
    },
    isFormattingActive(command) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        return document.queryCommandState(command);
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.text__formatter--container {
  .text-formatter {
    min-height: 27px;
    position: relative;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1px;
    color: $brand-neutral-700;
    &:focus,
    &:visited {
      border: none;
      outline: none;
    }
  }
  .format-block {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .tooltip-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    border-radius: 6px;
    background-color: $brand-primary-700;
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
    gap: 5px;
    z-index: 2;
    opacity: 1;
  }
  .tooltip-content {
    display: flex;
    flex-direction: row;
    gap: 5px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 8px;
      }
      &:hover {
        background-color: $brand-primary-400;
      }
      &.active {
        background-color: $brand-neutral-0;
      }
      img {
        width: 23px;
      }
    }
  }
  .link-action {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 16px;
    border-radius: 100px;
    background-color: $brand-neutral-0;
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
    gap: 5px;
    z-index: 1;
    input {
      flex-grow: 1;
      border: none;
      &:focus,
      &:visited {
        border: none;
        outline: none;
      }
    }
    .link-action-btn {
      cursor: pointer;
      width: 32px;
      height: 32px;
      @include flex-horizontal-center;
      border-radius: 50%;
    }
    .save-link {
      &:hover {
        background-color: $brand-primary-100;
      }
    }
    .remove-link {
      &:hover {
        background-color: $brand-error-100;
      }
    }
  }

  [contentEditable="true"]:empty:before {
    content: attr(placeholder);
    cursor: text;
    color: $brand-neutral-300;
  }
}
[dir="rtl"] {
  .text__formatter--container {
    .format-block {
      left: auto;
      right: 20px;
    }
  }
}
</style>
